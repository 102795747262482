import React, { useState, useEffect } from "react";
import BlogItemContainer from "../containers/blog/blog-item";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Loading from "../components/Loading";
import { baseURL } from "../App";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";




const BlogPage = () => {

    const pagelimit = 3;

    const formattedDate = (originalDateString) => {
        const originalDate = new Date(originalDateString);

        const day = originalDate.getDate().toString().padStart(2, "0");
        const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz.
        const year = originalDate.getFullYear();

        return `${day}.${month}.${year}`;
    };

    const [data, setdata] = useState(null);
    const [blogs, setblogs] = useState(null);
    const [query, setquery] = useState("");
    const [page, setpage] = useState(1);
    const [lastPosts, setlastPosts] = useState(null);
    const [count, setcount] = useState(0);


    useEffect(() => {
        const getinfo = async () => {
            try {
                const inf = await axios.get(
                    `${baseURL}items/blog_page?fields=*.*&limit=${pagelimit}`
                );

                setdata(inf.data.data);
                const last = await axios.get(
                    `${baseURL}items/blogs?fields=*.*&limit=3&sort=-date_created`
                );

                setlastPosts(last.data.data);
            } catch (error) {
                console.log(error)
            }

        };
        getinfo();
    }, []);




    const [settings, setSettings] = useState(null);
    
        useEffect(() => {
            try {
                axios
                    .get(`${baseURL}items/settings?fields=*.*`)
                    .then((response) => {
                        // Verinin doğru formatta olup olmadığını kontrol et
                        if (response.data) {
                            // Veriyi state'e set etme
                            setSettings(response.data.data);
                        } else {
                            console.error(
                                "Invalid data format received from Directus."
                            );
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching data from Directus:", error);
                    });
            } catch (error) {
                console.error("An error occurred in useEffect:", error);
            }
        }, []);



    useEffect(() => {
        const getinfo = async () => {
            try {
                let productsUrl = `${baseURL}items/blogs?fields=*.*&limit=${pagelimit}&page=${page}&filter[status]=published`;
                let countUrl = `${baseURL}items/blogs?fields=*.*&filter[status]=published&aggregate[count]=*`;

                if (query) {
                    productsUrl += `&search=${query.toLowerCase()}`;
                    countUrl += `&search=${query.toLowerCase()}`;
                    setpage(1);
                }



                const productss = await axios.get(productsUrl);
                setblogs(productss.data.data);

                const blgcount = await axios.get(countUrl);
                setcount(Math.ceil(blgcount.data.data[0].count / pagelimit));
            } catch (error) {
                console.error("Veri çekilirken hata oluştu:", error);
            }
        };

        getinfo();
    }, [query, page]);

    const handlePageClick = (event) => {
        setpage(event.selected + 1);
    };

    useEffect(() => {
        console.log(data, blogs, lastPosts)
    }, [data, blogs, lastPosts])


    if (data === null || blogs === null || settings === null) return <Loading />

    return (
        <>
        {settings?.blog_active === true ? (

        <React.Fragment>
            <Layout>
                <SEO title={data?.seo_title} />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={data?.banner_title}
                        excerpt={data?.banner_text}
                        image={`${baseURL}assets/${data?.banner_img?.id}`}
                    />

                    <section className="blog-section section-py">
                        <div className="container">
                            <div className="row mb-n7">

                                {blogs?.length > 0 ? (
                                    blogs.map((blog, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="col-lg-4 col-sm-6 mb-7"
                                            >
                                                <div className="blog-card">
                                                    <div className={`thumb bg-light text-center `}>
                                                        <Link to={`/blog-details/${blog?.blog_link}`}>
                                                            <img
                                                                src={`${baseURL}assets/${blog?.img?.id}`}
                                                                alt="img"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-content">
                                                        {/* <Link to={`/blog-details/${blog?.blog_link}`}>
                                                            <span className="blog-meta author"></span>
                                                        </Link> */}
                                                        <span className="separator">-</span>
                                                        <Link to={`/blog-details/${blog?.blog_link}`}>
                                                            <span className="blog-meta date">{blog?.date_created &&
                                                                formattedDate(blog?.date_created)}</span>
                                                        </Link>
                                                        <h3 className="title">
                                                            <Link to={`/blog-details/${blog?.blog_link}`}>
                                                                {blog.title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    ""
                                )}
                            </div>

                        </div>

                        {count && count > 1 && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                <ReactPaginate
                                    nextLabel={
                                        <i
                                            className="fa-solid fa-arrow-right"
                                            style={{
                                                color: "white",
                                                ":hover": { color: "#frf" },
                                                cursor: "pointer",
                                            }}
                                        ></i>
                                    }
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={1}
                                    pageCount={count && count}
                                    previousLabel={
                                        <i
                                            className="fa-solid fa-arrow-left"
                                            style={{
                                                color: "white",
                                                cursor: "pointer",
                                            }}
                                        ></i>
                                    }
                                    pageClassName="page-items"
                                    pageLinkClassName="page-links btn btn-outline-secondary mx-1"
                                    previousClassName="page-items btn btn-outline-secondary"
                                    previousLinkClassName="page-links"
                                    nextClassName="page-items btn btn-outline-secondary"
                                    nextLinkClassName="page-links"
                                    breakLabel="..."
                                    breakClassName="page-items btn btn-outline-secondary"
                                    breakLinkClassName="page-links"
                                    containerClassName="pagination d-flex"
                                    activeClassName="active"
                                    activeLinkClassName="btn btn-primary"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )}

                    </section>


                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    ) : (
        ""
        )}
        </>
    );
};

export default BlogPage;
