import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { baseURL } from "../App";
import axios from "axios";
import Loading from "../components/Loading";

const Tokenomics = () => {
    const [tokenomics, settokenomics] = useState(null);

    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/tokenomics?fields=*.*`)
                .then((response) => {
                    settokenomics(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);

    if (tokenomics === null) return <Loading />;

    return (
        <React.Fragment>
            <Layout>
                <SEO title={tokenomics?.seo_title} />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={tokenomics?.breadcrumb_title}
                        excerpt={tokenomics?.breadcrumb_text}
                        image={`${baseURL}assets/${tokenomics.img?.id}`}
                    />

                    {/*                     <AppChart />
                    <TokenomicsPieChart />
                    <TokenomicsTable /> */}

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 mb-5">
                                <div
                                    data-depth="0.2"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h2 className="mt-5">
                                        {tokenomics?.title}
                                    </h2>

                                    <img
                                        className=" mb-1"
                                        src={`${baseURL}assets/${tokenomics?.tokenomics_img?.id}`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Tokenomics;
