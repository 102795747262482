import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { baseURL } from "../../../App";
import axios from "axios";

const MainMenu = () => {
    const [isHovered, setIsHovered] = useState(false);

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await axios.get(
                    `${baseURL}items/header?fields=*.*`
                );
                if (response.data && Array.isArray(response.data.data)) {
                    setMenuItems(response.data.data);
                } else {
                    console.error(
                        "Invalid data format received from Directus."
                    );
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };

        fetchMenuItems();
    }, []);

    const downloadFile = async (fileId) => {
        if (!fileId) {
            console.error("File ID is missing!");
            return;
        }

        try {
            const response = await axios.get(`${baseURL}assets/${fileId}`, {
                responseType: "blob", // Dosyayı blob formatında alıyoruz
            });

            // Blob verisini dosya olarak indir
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `file_${fileId}.pdf`; // İndirilen dosyanın adı
            link.click();

            // URL'yi serbest bırak
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <nav className="main-menu d-none d-lg-block">
            <ul
                className="d-flex"
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {menuItems &&
                    menuItems.map((e) => (
                        <li key={e.id}>
                            {e?.link ? (
                                <NavLink
                                    exact
                                    to={e?.link}
                                    target={e.target ? "_blank" : "_self"}
                                    rel={e?.target ? "noopener noreferrer" : ""}
                                >
                                    {e?.title}
                                </NavLink>
                            ) : e?.pdf?.id ? (
                                <button
                                    className="title"
                                    style={{
                                        color: isHovered ? "#59e3a7" : "white",
                                        fontSize: "20px",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        fontFamily:"Metropolis Semi Bold",
                                        lineHeight:"2.3"

                                    }}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={() => downloadFile(e?.pdf.id)}
                                >
                                    {e?.title}
                                </button>
                            ) : (
                                ""
                            )}
                        </li>
                    ))}
            </ul>
        </nav>
    );
};

export default MainMenu;
