
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";
import { baseURL } from "../../../App";
import axios from "axios";



const MobileMenu = ({ show, onClose }) => {

    
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };


    const [menuItems, setMenuItems] = useState([]);


    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await axios.get(`${baseURL}items/header?fields=*.*`)
                if (response.data && Array.isArray(response.data.data)) {
                    setMenuItems(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };

        fetchMenuItems();
    }, []);




    const [genaralsettings, setgenaralsettings] = useState(null);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/genaral_settings?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setgenaralsettings(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



        const downloadFile = async (fileId) => {
            if (!fileId) {
                console.error("File ID is missing!");
                return;
            }
    
            try {
                const response = await axios.get(`${baseURL}assets/${fileId}`, {
                    responseType: "blob", // Dosyayı blob formatında alıyoruz
                });
    
                // Blob verisini dosya olarak indir
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.download = `file_${fileId}.pdf`; // İndirilen dosyanın adı
                link.click();
    
                // URL'yi serbest bırak
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        };
    


    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${baseURL}assets/${genaralsettings?.logo?.id}`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>

                    <nav id="offcanvasNav" className="offcanvas-menu">
                        {menuItems && menuItems.map((e) => (
                            <ul key={e.id}>
                                <li>
                            {e?.link ? (

                                    <NavLink exact 
                                    to={e?.link}
                                    target={e.target ? "_blank" : "_self"}
                                    rel={e?.target ? "noopener noreferrer" : ""}
                                    >
                                        {e.title}
                                    </NavLink>
                                    ) : e?.pdf?.id ? (
                                        <button
                                            className="title"
                                            style={{
                                                fontSize: "16px",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                fontFamily:"Metropolis Semi Bold",
                                                lineHeight:"2.3",
                                                padding:"15px"
        
                                            }}

                                            onClick={() => downloadFile(e?.pdf.id)}
                                        >
                                            {e?.title}
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </li>
                            </ul>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
};



export default MobileMenu;
