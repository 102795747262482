import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SidebarCategories from "../../../components/sidebar/categories";
import SidebarSearch from "../../../components/sidebar/search";
import SidebarTag from "../../../components/sidebar/sidbar-tag";
import SidebarArchive from "../../../components/sidebar/sidebar-archive";
import SidebarPost from "../../../components/sidebar/sidebar-post";
import SidebarTitle from "../../../components/sidebar/sidebar-title";
import BlogData from "../../../data/blog.json";
import BlogDetailsWrap from "../../../components/blog-details";
import Comment from "../../../components/comment";
import { baseURL } from "../../../App";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";


const formattedDate = (originalDateString) => {
    const originalDate = new Date(originalDateString);

    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz.
    const year = originalDate.getFullYear();

    return `${day}.${month}.${year}`;
};


const BlogDetailsContainer = ({ data }) => {



    const [item, setitem] = useState(null);
    const [blogs, setblogs] = useState(null);
    const [error, seterror] = useState(null);

    const { url } = useParams();


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/blogs?filter[status][_eq]=published`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setitem(response.data.data[0]);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);

    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/blogs?filter[status][_eq]=published&limit=3`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setblogs(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);

    useEffect(() => {
        console.log(url)
    }, [url])


    return (


        <div className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-8 col-lg-8 mb-7">
                        {item ? (

                            <div className="blog-details-content"
                            >
                                {/* <BlogDetailsWrap data={data} /> */}

                                <div className="blog-comments ">
                                    <h3 className="blog-comment-title text-white">
                                        {item?.title}
                                    </h3>
                                    <p>{item?.text}</p>
                                </div>
                                <div className="blog-details-thumb">
                                    <img
                                        src={`${baseURL}assets/${item?.img}`}
                                        alt="img"
                                    />

                                </div>
                            </div>


                        ) : (
                            <h5>
                                Blog couldn't found
                            </h5>
                        )}
                    </div>
                    <div className="col-xl-3 col-lg-4 mb-7 offset-xl-1">
                        <div className="widget-wrapper widget-wrapper-nl">


                            <div className="sidebar-widget">
                                <SidebarTitle title="Search" />
                                <div className="widget-content">
                                    <div className="widget-search">
                                        <form action="#">
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="Type your keyword..."
                                                style={{ color: "black" }}
                                            />
                                            <button className="widget-search-btn">
                                                <i className="icofont-search"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Categories" />
                                <SidebarCategories data={BlogData} />
                            </div> */}


                            <div className="sidebar-widget">
                                <SidebarTitle title="popular post" />
                                {/* <SidebarPost data={BlogData} /> */}


                                <div className="widget-post-wrap text-white">
                                    {blogs?.length > 0 ? (
                                        blogs.map((blog, i) => (
                                            <div
                                                key={i}
                                                className="widget-post-list flex items-center gap-4"
                                            >
                                                {/* Image on the left */}
                                                <Link to={`/blog-details/${blog?.blog_link}`} className="post-thumb">
                                                    <img
                                                        src={`${baseURL}assets/${blog?.img}`}
                                                        alt="img"
                                                        className="w-24 h-24 object-cover" // Adjust size as needed
                                                    />
                                                </Link>

                                                {/* Content on the right */}
                                                <div className="widget-post-content flex-1">
                                                    <h3 className="widget-sub-title text-lg font-semibold">
                                                        <Link to={`/blog-details/${blog?.blog_link}`} className="hover:underline">
                                                            {blog?.title}
                                                        </Link>
                                                    </h3>
                                                    <p className="post-meta text-sm text-gray-400">
                                                        {blog?.date_created && formattedDate(blog?.date_created)}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        ""
                                    )}
                                </div>





                            </div>


                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Archive" />
                                <SidebarArchive data={BlogData} />
                            </div> */}


                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Tags" />
                                <SidebarTag data={BlogData} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetailsContainer;
