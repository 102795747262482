import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import Certified from "./pages/certified";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import ServiceDetails from "./templates/service-details";
import TeamPage from "./pages/team";
import FaqPage from "./pages/faq";
import BlogPage from "./pages/blog";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";
import BlogRightSidebarPage from "./pages/blog-right-sidebar";
import BlogDetailsPage from "./templates/blog-details";
import BlogAuthor from "./templates/blog-author";
import BlogDate from "./templates/blog-date";
import BlogTag from "./templates/blog-tag";
import BlogCategory from "./templates/blog-category";
import ContactPage from "./pages/contact";
import Tokenomics from "./pages/Tokenomics";
import NotFound from "./pages/notfound";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";

import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bsc } from "wagmi/chains";
import {
    ConnectKitProvider,
    ConnectKitButton,
    getDefaultConfig,
} from "connectkit";
import HelmetAll from "./components/Helmet";

export const config = createConfig({
    chains: [bsc],
    transports: {
        [bsc.id]: http(),
    },
});

export const baseURL = "https://panel.airdiamond.net/";

const App = () => {
    const conf = createConfig(
        getDefaultConfig({
            // Required API Keys
            alchemyId: "10Fc6pdvnDgybLghIHwQ26X1uDTi0exx", // or infuraId
            walletConnectProjectId: "ec3f2fd89ba1113b790d7d2102c2246b",

            // Required
            appName: "Lincoln Token",

            // Optional
            chains: [bsc],
        })
    );

    const queryClient = new QueryClient();

    const Web3Provider = ({ children }) => {
        return (
            <WagmiProvider config={conf}>
                <QueryClientProvider client={queryClient}>
                    <ConnectKitProvider>{children}</ConnectKitProvider>
                </QueryClientProvider>
            </WagmiProvider>
        );
    };

    return (
        <Router>
            <NavScrollTop>
                <HelmetAll />
                <Web3Provider>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            exact
                            component={HomePage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/about"}`}
                            component={AboutPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/certified"}`}
                            component={Certified}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/tokenomics"}`}
                            component={Tokenomics}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/service"}`}
                            component={ServicePage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/service-details/:id"
                            }`}
                            component={ServiceDetails}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/team"}`}
                            component={TeamPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/faq"}`}
                            component={FaqPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/contact"}`}
                            component={ContactPage}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL + "/blog"}`}
                            component={BlogPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-left-sidebar"
                            }`}
                            component={BlogLeftSidebarPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-right-sidebar"
                            }`}
                            component={BlogRightSidebarPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-details/:url"
                            }`}
                            component={BlogDetailsPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/author/:author"
                            }`}
                            component={BlogAuthor}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/date/:date"}`}
                            component={BlogDate}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                            component={BlogTag}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/category/:slug"
                            }`}
                            component={BlogCategory}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "*"}`}
                            component={NotFound}
                        />
                    </Switch>
                </Web3Provider>
            </NavScrollTop>
        </Router>
    );
};

export default App;
