import React, { useState, useEffect } from "react";
import axios from "axios";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import Loading from "../components/Loading";
import { baseURL } from "../App";
import { Link, useParams } from "react-router-dom";

const PageSingle = () => {
    const [pageContent, setPageContent] = useState(null);
    const [notFoundContent, setNotFoundContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { url } = useParams();

    useEffect(() => {
        const fetchPageContent = async () => {
            try {
                const response = await axios.get(
                    `${baseURL}items/custom_pages?fields=*.*&filter[link][_eq]=${url}`
                );

                if (response.data?.data?.length > 0) {
                    setPageContent(response.data.data[0]);
                    setError(false);
                } else {
                    setError(true);
                }
            } catch (err) {
                console.error("Error fetching page content:", err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchPageContent();
    }, [url]);

    useEffect(() => {
        const fetchNotFoundContent = async () => {
            try {
                const response = await axios.get(`${baseURL}items/not_found?fields=*.*`);
                setNotFoundContent(response.data?.data || null);
            } catch (err) {
                console.error("Error fetching not found content:", err);
            }
        };

        if (error) {
            fetchNotFoundContent();
        }
    }, [error]);

    if (loading) {
        return <Loading />;
    }

    if (pageContent) {
        return (
            <Layout>
                <SEO title={pageContent?.banner_title} />
                <div className="wrapper">
                    <Header />
                    <div dangerouslySetInnerHTML={{ __html: pageContent?.content }}></div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <SEO title="Page Not Found" />
            <div className="wrapper">
                <Header />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "50px",
                        marginTop: "150px",
                    }}
                >
                    <h2>{notFoundContent?.banner_title || "Page Not Found"}</h2>
                    <p>
                        {notFoundContent?.title || "The page you're looking for doesn't exist."}{" "}
                        <Link to="/">{notFoundContent?.text || "Go back to Home"}</Link>.
                    </p>
                </div>
                <Footer />
                <ScrollToTop />
            </div>
        </Layout>
    );
};

export default PageSingle;
