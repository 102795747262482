import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Parallax from "parallax-js";
import { baseURL } from "../../App";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../Loading";

const Intro = ({ data }) => {
    // const sceneEl = useRef(null);

    // useEffect(() => {
    //     const parallaxInstance = new Parallax(sceneEl.current, {
    //         relativeInput: true,
    //     });

    //     parallaxInstance.enable();

    //     return () => parallaxInstance.disable();
    // }, []);

    const [banner, setBanner] = useState(null);

    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/banner?fields=*.*`)
                .then((response) => {
                    setBanner(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);

    if (banner === null) return <Loading />;

    return (
        <div className="hero-slider">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        {banner && banner && (
                            <div className="hero-slide-content">
                                <h2
                                    className="title animated mt-3"
                                    style={{ fontSize: "30px" }}
                                >
                                    {banner?.title}
                                </h2>
                                <h3
                                    className=" animated mb-4"
                                    style={{ fontSize: "20px", color: "#fff" }}
                                >
                                    {banner?.text}
                                </h3>

                                {banner?.button_link &&
                                banner?.button_link[0] === "/" ? (
                                    <Link
                                        to={banner?.button_link}
                                        className="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                    >
                                        {banner?.button_text}
                                    </Link>
                                ) : banner?.button_link ? (
                                    <a
                                        href={banner?.button_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                    >
                                        {banner?.button_text}
                                    </a>
                                ) : null}
                            </div>
                        )}
                    </div>
                    <div className="col-lg-6 mt-5">
                        {/* <div
                            className="hero-img"
                            id="scene"
                        > */}
                        <div data-depth="0.2">
                            <img
                                className=" mb-1"
                                src={`${baseURL}assets/${banner.img?.id}`}
                                alt=""
                            />
                        </div>
                        {/*</div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
