import { useState, useEffect } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import { baseURL } from "../../App";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading.js";
import { useForm } from "react-hook-form";



const AboutContainer = () => {

    const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const submitForm = (formData) => {
		console.log("Submite Form Data = ", formData);
	};


    const [certified, setCertified] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);

    const [mesajData, setmesajData] = useState({
        name: "",
        email: "",
        message: "",
    });

    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/certified?fields=*.*`)
                .then((response) => {
                    if (response.data) {
                        setCertified(response.data.data);
                    } else {
                        console.error(
                            "Invalid data format received from Directus."
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);


    const postMessage = async () => {
		try {
			console.log("Gönderilen Veri:", mesajData);
			const res = await axios.post(`${baseURL}items/form?fields=*.*`, mesajData);
			if (res.status === 200 || res.status === 201) {
				alert("Form submitted!");
				setmesajData({
					isim: "",
					email: "",
					telefon: "",
					mesaj: "",
				});
				console.log(setmesajData)
			} else {
				console.error("Sunucu Hatası:", res);
				alert("Bir hata oluştu. Lütfen tekrar deneyin.");
			}
		} catch (error) {
			console.error("API Hatası:", error);
			alert("Bir hata oluştu. Lütfen tekrar deneyin.");
		}
	};

    
    const handleChange = (e) => {
        setmesajData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    if (certified === null || mesajData === null) return <Loading />;

    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content mt-4">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={`${certified?.why_us_title} `}
                                title=""
                            />
                            <h4 className="text-white mb-3">
                                {`${certified?.why_us_text} `}
                            </h4>
                            <p className="mb-5">{`${certified?.why_us_content} `}</p>

                            {/* {certified?.button_link2 &&
                            certified?.button_link2[0] === "/" ? (
                                <Link
                                    to={certified?.button_link2}
                                    className="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                >
                                    {certified?.button_text2}
                                </Link>
                            ) : certified?.button_link2 ? (
                                <a
                                    href={certified?.button_link2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                >
                                    {certified?.button_text2}
                                </a>
                            ) : null} */}



<button
                                className="btn btn-lg btn-dark"
                                onClick={() => setModalOpen(true)}
                            >
                                Open Contact Form
                            </button>

                            {/* Modal */}
                            {isModalOpen && (
                                <div
                                    className="modal-overlay"
                                    onClick={() => setModalOpen(false)}
                                    style={{
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                                        zIndex: 9999,
                                    }}
                                >
                                    <div
                                        className="modal-content"
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            maxWidth: "600px",
                                            margin: "0 auto",
                                            padding: "20px",
                                            backgroundColor: "white",
                                            borderRadius: "8px",
                                            position: "relative",
                                        }}
                                    >
                                        <button
                                            className="modal-close"
                                            onClick={() => setModalOpen(false)}
                                            style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                                fontSize: "24px",
                                                border: "none",
                                                background: "transparent",
                                                cursor: "pointer",
                                            }}
                                        >
                                            &times;
                                        </button>
                                        <h2>Contact Us </h2>
                                        <form
                                            id="contactForm"
                                            className="row"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                postMessage();
                                                
                                            }}
                                        >
                                            <div className="col-12 col-sm-6 mb-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    value={mesajData.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    ref={register({
                                                        required:
                                                            "Name is required",
                                                    })}
                                                    style={{ color: "black" }}
                                                />
                                                {errors?.name && (
                                                    <p>{errors.name.message}</p>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 mb-7">
                                                <input
                                                    value={mesajData.email}
                                                    onChange={handleChange}
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Your Mail"
                                                    ref={register({
                                                        required:
                                                            "Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message:
                                                                "Invalid email address",
                                                        },
                                                    })}
                                                    style={{ color: "black" }}
                                                />
                                                {errors?.email && (
                                                    <p>
                                                        {errors.email.message}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-12 mb-9">
                                                <textarea
                                                    value={mesajData.message}
                                                    onChange={handleChange}
                                                    className="form-control massage-control"
                                                    name="message"
                                                    id="massage"
                                                    cols="30"
                                                    rows="10"
                                                    placeholder="Your Message"
                                                    ref={register({
                                                        required:
                                                            "Message is required",
                                                    })}
                                                    style={{ color: "black" }}
                                                ></textarea>
                                                {errors?.message && (
                                                    <p>
                                                        {errors.message.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    id="contactSubmit"
                                                    type="submit"
                                                    className="btn btn-dark btn-hover-dark"
                                                    data-complete-text="Well Done!"
                                                    
                                                >
                                                    {"Submit"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${baseURL}assets/${certified.img?.id}`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-8 pt-8">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div className="about-content mt-4">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={`${certified?.title} `}
                                title=""
                            />
                            <p className="mb-5">{`${certified?.content} `}</p>

                            {/* {certified?.button_link &&
                            certified?.button_link[0] === "/" ? (
                                <Link
                                    to={certified?.button_link}
                                    className="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                >
                                    {certified?.button_text}
                                </Link>
                            ) : certified?.button_link ? (
                                <a
                                    href={certified?.button_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                >
                                    {certified?.button_text}
                                </a>
                            ) : null} */}

                            <button
                                className="btn btn-lg btn-dark"
                                onClick={() => setModalOpen(true)}
                            >
                                Open Contact Form
                            </button>

                            {/* Modal */}
                            {isModalOpen && (
                                <div
                                    className="modal-overlay"
                                    onClick={() => setModalOpen(false)}
                                    style={{
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                                        zIndex: 9999,
                                    }}
                                >
                                    <div
                                        className="modal-content"
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            maxWidth: "600px",
                                            margin: "0 auto",
                                            padding: "20px",
                                            backgroundColor: "white",
                                            borderRadius: "8px",
                                            position: "relative",
                                        }}
                                    >
                                        <button
                                            className="modal-close"
                                            onClick={() => setModalOpen(false)}
                                            style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                                fontSize: "24px",
                                                border: "none",
                                                background: "transparent",
                                                cursor: "pointer",
                                            }}
                                        >
                                            &times;
                                        </button>
                                        <h2>Contact Us </h2>
                                        <form
                                            id="contactForm"
                                            className="row"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                postMessage();
                                                
                                            }}
                                        >
                                            <div className="col-12 col-sm-6 mb-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    value={mesajData.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    ref={register({
                                                        required:
                                                            "Name is required",
                                                    })}
                                                    style={{ color: "black" }}
                                                />
                                                {errors?.name && (
                                                    <p>{errors.name.message}</p>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 mb-7">
                                                <input
                                                    value={mesajData.email}
                                                    onChange={handleChange}
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Your Mail"
                                                    ref={register({
                                                        required:
                                                            "Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message:
                                                                "Invalid email address",
                                                        },
                                                    })}
                                                    style={{ color: "black" }}
                                                />
                                                {errors?.email && (
                                                    <p>
                                                        {errors.email.message}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-12 mb-9">
                                                <textarea
                                                    value={mesajData.message}
                                                    onChange={handleChange}
                                                    className="form-control massage-control"
                                                    name="message"
                                                    id="massage"
                                                    cols="30"
                                                    rows="10"
                                                    placeholder="Your Message"
                                                    ref={register({
                                                        required:
                                                            "Message is required",
                                                    })}
                                                    style={{ color: "black" }}
                                                ></textarea>
                                                {errors?.message && (
                                                    <p>
                                                        {errors.message.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    id="contactSubmit"
                                                    type="submit"
                                                    className="btn btn-dark btn-hover-dark"
                                                    data-complete-text="Well Done!"
                                                    
                                                >
                                                    {"Submit"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${baseURL}assets/${certified.img2?.id}`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
