import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import Loading from "../components/Loading";
import PageSingle from "../components/pagesingle";
import { baseURL } from "../App";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const NotFound = () => {
    const [notFoundContent, setNotFoundContent] = useState(null); // Not Found içeriği
    const [pageContent, setPageContent] = useState(null); // Sayfa içeriği
    const [loading, setLoading] = useState(true); // Yükleme durumu
    const [error, setError] = useState(false); // Hata durumu

    const { url } = useParams(); // URL parametresini al

    // Not Found içeriğini getir
    useEffect(() => {
        const fetchNotFoundContent = async () => {
            try {
                const response = await axios.get(`${baseURL}items/not_found?fields=*.*`);
                setNotFoundContent(response.data?.data || null);
            } catch (err) {
                console.error("Error fetching not found content:", err);
            }
        };

        fetchNotFoundContent();
    }, []);

    // URL'ye göre sayfa içeriğini getir
    useEffect(() => {
        const fetchPageContent = async () => {
            try {
                const response = await axios.get(
                    `${baseURL}items/custom_pages?fields=*.*&filter[link][_eq]=${url}`
                );

                if (response.data?.data?.length > 0) {
                    setPageContent(response.data.data[0]); // Sayfa içeriği bulundu
                } else {
                    setError(true); // Sayfa içeriği bulunamadı
                }
            } catch (err) {
                console.error("Error fetching page content:", err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchPageContent();
    }, [url]);

    // Yükleme ekranı
    if (loading) {
        return <Loading />;
    }

    // Sayfa içeriği varsa
    if (pageContent) {
        return <PageSingle data={pageContent} />;
    }

    // Hata durumu veya içerik yoksa Not Found ekranını göster
    return (
        <React.Fragment>
            <Layout>
                <SEO title="AirDiamond – Page Not Found" />
                <div className="wrapper">
                    <Header />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "50px",
                            marginTop: "150px",
                        }}
                    >
                        <h2>{notFoundContent?.banner_title || "Page Not Found"}</h2>
                        <p>
                            {notFoundContent?.title || "The page you're looking for doesn't exist."}{" "}
                            <Link to="/">{notFoundContent?.text || "Go back to Home"}</Link>.
                        </p>
                        {notFoundContent?.img?.id && (
                            <img
                                src={`${baseURL}assets/${notFoundContent.img.id}`}
                                alt="Not Found"
                                className="mt-8 mb-4"
                                style={{ width: "400px" }}
                            />
                        )}
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default NotFound;
